<template>
  <div>
    <!-- 常规运单 -->
    <nav-bar :nav-info="navInfo" @screen="screen"></nav-bar>
    <!-- 筛选弹出框 -->

    <van-popup v-model="showScreen" closeable round position="bottom">
      <div class="screen_title">筛选</div>
      <h4>派发时间</h4>
      <div class="screen_time">
        <div>
          <el-date-picker
            type="date"
            placeholder="开始日期"
            value-format="yyyy-MM-dd"
            v-model="screenForm.createTimeStart"
            style="width: 90%"
          ></el-date-picker>
        </div>
        <div>
          <el-date-picker
            type="date"
            placeholder="结束日期"
            value-format="yyyy-MM-dd"
            v-model="screenForm.createTimeEnd"
            style="width: 90%"
          ></el-date-picker>
        </div>
      </div>
      <template>
        <h4>司机姓名</h4>
        <div class="screen_orderNo">
          <van-field
            v-model="screenForm.driverName"
            clearable
            placeholder="请输入司机姓名"
          />
        </div>
        <h4>订单号</h4>
        <div class="screen_orderNo">
          <van-field
            v-model="screenForm.orderNo"
            clearable
            placeholder="请输入订单号"
          />
        </div>
        <h4>原始订单号</h4>
        <div class="screen_ys_orderNo">
          <van-field
            v-model="screenForm.receiveOrderNo"
            clearable
            placeholder="请输入原始订单号"
          />
        </div>
      </template>

      <div class="screen_sub">
        <div @click="screenBtn">确定</div>
      </div>
    </van-popup>
    <form action="javascript:return ture">
      <van-search
        v-model="searchValue"
        class="searchValue"
        shape="round"
        @search="onSearch"
        clearable
        @clear="ClearSearch"
        placeholder="请输入搜索订单号"
      />
    </form>
    <!-- <div
      class="main"
      v-infinite-scroll="getList"
      infinite-scroll-delay="500"
      infinite-scroll-disabled="scorllLoading"
      infinite-scroll-distance="50"
    > -->
    
        <div v-if="!dataList.code">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="scorllLoading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getList"
              class="main"
            >
              <div
                class="deriver_main"
                v-for="(item, index) in dataList"
                :key="index"
                @click="showWayBill(item)"
              >
                <!-- 司机信息 -->
                <div class="driverInfo" v-if="item.driverInfo && Object.keys(item.driverInfo).length > 0">
                  <div class="driverInfo_detail">
                    <p class="driver_name">
                      {{ item.driverInfo.driverName }}
                      <span class="useYd" v-if="item.orderInfo.length > 0"
                        >有运单</span
                      >
                      <span class="noYd" v-else>无运单</span>
                    </p>
                  </div>
                  <div class="driverInfo_detail">
                    <span>车牌号：{{ item.driverInfo.carNo }}</span>
                    <span>电话：{{ item.driverInfo.phone }}</span>
                  </div>
                </div>
                <div v-else class="list-status">
                  <i class="list-outer-ring"></i>
                  <i class="list-inner-circle"></i>
                  <span>补</span>
                </div>
                <div
                  class="fenge"
                  v-if="item.orderInfo && item.orderInfo.length > 0"
                ></div>
                <!--订单信息  -->
                <div class="orderInfo">
                  <div
                    class="mark_ddd"
                    v-if="item.orderInfoStatu == false"
                    @click.stop="ChangeOrderInfoStatu(true, item)"
                  ></div>
                  <!-- 折叠按钮 -->
                  <div
                    class="mark_zk"
                    v-else-if="item.orderInfoStatu == true"
                    @click.stop="ChangeOrderInfoStatu(false, item)"
                  ></div>
                  <div v-if="item.orderInfo && item.orderInfo.length > 0">
                    <h4>运单信息</h4>
                    <div class="orderListBox">
                      <div
                        class="orderList"
                        v-for="(infoItem, infoIndex) in showList(item)"
                        :key="infoIndex"
                      >
                        <span>{{ infoItem.orderNo }}</span>
                        <span class="orderList_time"
                          ><van-image
                            :src="require('@/assets/img/icon/waybill_time2x.png')"
                          ></van-image>
                          {{
                            infoItem.createTime | formatDate("YYYY/MM/DD HH:mm:ss")
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
        <div v-else>
          <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/empty-image-default.png"
            description="没有找到运单信息"
          />
        </div>
        <!-- 弹出层 -->
        <van-popup v-model="showPopwaybill" closeable round position="bottom" @close="closeClick">
          <!-- 有司机信息 -->
          <div v-if="orderDetails.driverInfo && Object.keys(orderDetails.driverInfo).length > 0">
            <div v-show="qrcodeShow" class="qrcodebox">
              <van-icon
                @click="closeQrcode"
                name="cross"
                size="30"
                color="#0094ff"
              />
              <div class="order_no">订单号：{{ qrcodeItems.orderNo }}</div>
              <img ref="qrcode" class="qrcodeImg" :src="URL" />
              <div class="order_userinfo_rigth">
                <div class="order_userinfo_rigth1">
                  <div class="order_userinfo_rigth_item">
                    <span style="overflow: hidden"
                      >收货人：{{ qrcodeItems.receiverName }}</span
                    >
                  </div>
                  <div class="order_userinfo_rigth_item">
                    <span class="tel_icon">{{ qrcodeItems.phone }}</span>
                  </div>
                </div>
                <!-- 收货地址 -->
                <div class="order_userinfo_rigth_adress">
                  <div class="adress_text">{{ qrcodeItems.address }}</div>
                </div>
                <!-- 参数 -->
                <div class="order_userinfo_rigth1">
                  <span>体积：{{ qrcodeItems.cargoSize | toFixed(2) }}m³</span>
                  <span>重量：{{ qrcodeItems.cargoWeight | toFixed(2) }}t</span>
                  <span>数量：{{ qrcodeItems.cargoNum | toFixed(2) }}</span>
                </div>
              </div>
            </div>
            <div class="detail_title">运单详情</div>
            <div class="orderBox">
              <div class="order" v-for="(item, index) in orderDetails.orderInfo" :key="index">
                <div class="order_tile">
                  <div class="order_tile_container">
                    <div class="order_title_img1">
                      <van-image
                        :src="require('@/assets/img/icon/cg_order2x.png')"
                      ></van-image>
                    </div>
                    <div class="order_no">订单号：{{ item.orderNo }}</div>
                    <div class="order_time">
                      {{ item.createTime | formatDate("YYYY/MM/DD HH:mm") }}
                    </div>
                  </div>
                </div>
                <div class="order_container">
                  <div class="fenge"></div>
                  <div class="order_userinfo">
                    <!-- check选择框 -->
                    <div class="order_userinfo_check">
                      <div class="checked">
                        <van-checkbox-group
                          v-model="checkedResult"
                          ref="checkboxGroup"
                        >
                          <van-checkbox
                            :name="item"
                            checked-color="#FD5C0C"
                          ></van-checkbox>
                        </van-checkbox-group>
                      </div>
                    </div>
                    <!-- 右边订单信息 -->
                    <div class="order_userinfo_rigth">
                      <div class="order_userinfo_rigth1">
                        <div class="order_userinfo_rigth_item">
                          <span style="overflow: hidden"
                            >收货人：{{ item.receiverName }}</span
                          >
                        </div>
                        <div class="order_userinfo_rigth_item">
                          <span class="tel_icon">{{ item.phone }}</span>
                        </div>
                      </div>
                      <!-- 收货地址 -->
                      <div class="order_userinfo_rigth_adress">
                        <div class="adress_text">{{ item.address }}</div>
                      </div>
                      <!-- 参数 -->
                      <div class="order_userinfo_rigth1">
                        <span>体积：{{ item.cargoSize | toFixed(2) }}m³</span>
                        <span>重量：{{ item.cargoWeight | toFixed(2) }}t</span>
                        <span>数量：{{ item.cargoNum | toFixed(2) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <i class="qrcodeIcon" @click.stop="openQrcode(item)"></i>
              </div>
            </div>
            <!-- 弹出层按钮 -->
            <div class="footer">
              <van-uploader :after-read="afterRead" :before-read="beforeRead">
                <!-- <div class="btn_qx" @click="UploadReceipt">上传回执单</div> -->
                <div class="btn_qx">上传回执单</div>
              </van-uploader>
              <div class="btn_sub" @click="dotClick">打点</div>
              <div class="btn_sub" @click="CloseOrder">结束运单</div>
            </div>
          </div>
          <!-- 无司机信息 -->
          <van-form v-else @submit="onSubmit" style="margin-top: 40px;">
            <van-field
              label-width="7em"
              v-model="form.carNo"
              name="carNo"
              label="车牌号"
              placeholder="车牌号"
              :rules="[{ required: true, message: '请填写车牌号' }]"
            />
            <van-field
              label-width="7em"
              v-model="form.driverName"
              name="driverName"
              label="司机"
              placeholder="司机"
              :rules="[{ required: true, message: '请填写司机' }]"
            />
            <van-field
              label-width="7em"
              v-model="form.driverPhone"
              name="driverPhone"
              label="司机联系电话"
              placeholder="司机联系电话"
              :rules="[{ required: true, message: '请填写司机联系电话' }]"
            />
            <van-field
              label-width="7em"
              v-model="form.vehicleLength"
              name="vehicleLength"
              label="车长(m)"
              placeholder="车长(m)"
              :rules="[{ required: true, message: '请填写车长(m)' }]"
            />
            <van-field
              label-width="7em"
              v-model="form.cargoSize"
              name="cargoSize"
              label="货物总体积(m³)"
              placeholder="货物总体积(m³)"
              :rules="[{ required: true, message: '请填写货物总体积(m³)' }]"
            />
            <van-field
              label-width="7em"
              v-model="form.cargoNum"
              name="cargoNum"
              label="货物总件数"
              placeholder="货物总件数"
              :rules="[{ required: true, message: '请填写货物总件数' }]"
            />
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </van-popup>
        <!-- <div class="noLoading">
          <span v-if="scorllLoading">已经到底了...</span>
          <span v-else>正在加载...</span>
        </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
// import QRCode from 'qrcodejs2'
import { Toast } from "vant";
import lrz from "lrz";
import NavBar from "../../components/NavBar/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      URL: "", //拼接后完整地址
      // qrcodeURL: 'http://10.15.7.18:8088/carrier/',//基地址
      qrcodeURL: "http://www.so56.net/carrier/", //正式基地址
      qrcodeShow: false, //二维码显示
      qrcodeItems: {}, //二维码订单详情
      navInfo: {
        title: "运力监控",
        left: true,
        rigth: true,
        path: "/home",
      },
      active: 0,
      searchValue: "",
      scorllLoading: false,
      showPopwaybill: false,
      checkedResult: [],
      showScreen: false,
      isActiveIndex: 0,
      //筛选条件
      screenForm: {
        orderNo: "",
        createTimeStart: "",
        createTimeEnd: "",
        driverName: "",
        receiveOrderNo: "",
      },
      dataList: [],
      orderDetails: [],
      query: {
        page: 0,
        size: 10,
      },
      refreshing: false,
      finished: false,
      // 无车辆信息时，需补全的数据
      form: {
        carNo: '',
        driverName: '',
        driverPhone: '',
        vehicleLength: '',
        cargoSize: '',
        cargoNum: '',
      }
    };
  },
  created() {
    // console.log(this.$store.state.userInfo.deptId);
  },
  methods: {
    //生成二维码
    openQrcode(item) {
      this.qrcodeItems = item;
      this.URL = this.qrcodeURL + item.crimg;
      this.qrcodeShow = true;
      // this.qrcode = new QRCode(this.$refs.qrcode, {
      //   text: URL, //页面地址 ,如果页面需要参数传递请注意哈希模式#
      //   width: 140, // 二维码宽度 （不支持100%）
      //   height: 140, // 二维码高度 （不支持100%）
      //   colorDark: '#000',
      //   colorLight: '#fff',
      //   correctLevel: QRCode.CorrectLevel.H,
      // })
    },
    //关闭清除二维码
    closeQrcode() {
      this.qrcodeShow = false;
      this.$refs.qrcode.url = "";
    },
    //搜索框确认事件
    screenBtn() {
      this.query.page = 0;
      this.dataList = [];
      this.noCarDataList = [];
      this.getList();
      this.scorllLoading = false;
      this.showScreen = false;
    },
    //搜索框事件
    async onSearch(value) {
      this.$router.push({
        path: `/regularWaybillSearch`,
        query: {
          orderNo: value,
        },
      });
    },
    //清除搜索框
    ClearSearch() {
      this.query.page = 0;
      this.dataList = [];
      this.noCarDataList = [];
      this.getList();
    },
    async getList(bool) {
      if (this.refreshing) {
        this.dataList = [];
        this.noCarDataList = [];
        this.refreshing = false;
        this.query.page = 1
      } else {
        this.query.page++;
      }
      let params = {
        userId: this.$store.state.userInfo.deptId,
        page: !bool ? this.query.page : 1,
        size: this.query.size,
        driverName: this.screenForm.driverName, //司机姓名
        orderNo: this.screenForm.orderNo, //订单号
        receiveOrderNo: this.screenForm.receiveOrderNo, //原始订单号
        transportNo: this.screenForm.transportNo, //计划号
        createTimeStart: this.screenForm.createTimeStart,
        createTimeEnd: this.screenForm.createTimeEnd,
      };
      let res = await this.$Api.transport(params);
      if (res.status != 200) return false;

      let arr = [];
      res.data.list.map((item) => {
        if (item.orderInfo.length > 3) {
          item.orderInfoStatu = false;
        }
        arr.push(item);
      });
      
      if(bool) {
        this.dataList = arr
      } else {
        this.dataList = [...this.dataList, ...arr]
      }

      if(this.query.page >= res.data.totalPage) {
        this.finished = true;
      }
      
      this.scorllLoading = false
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.scorllLoading = true;
      this.getList(true);
    },
    //点击显示隐藏更多
    showList(item) {
      if (item.orderInfoStatu == false) {
        var showList = [];
        if (item.orderInfo.length > 3) {
          for (let i = 0; i < 3; i++) {
            showList.push(item.orderInfo[i]);
          }
        } else {
          showList = item.orderInfo;
        }
        return showList;
      } else {
        return item.orderInfo;
      }
    },
    //点击mark折叠展开事件
    ChangeOrderInfoStatu(status, item) {
      item.orderInfoStatu = status;
      this.showList(item);
    },
    //显示运单详情弹窗
    showWayBill(item) {
      if (item.orderInfo.length != 0) {
        this.showPopwaybill = true;
        this.orderDetails = item;
        this.checkedResult = [];
      } else {
        Toast("暂无订单信息");
      }
    },

    //筛选弹窗事件
    screen(value) {
      this.showScreen = value;
    },

    //上传之前验证
    beforeRead(file) {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      // 参数dataurl base64字符串
      // 参数filename 图片名称
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    //上传回执单图片
    async afterRead(files) {
      if (this.checkedResult.length <= 0) {
        Toast.fail("请先选择订单");
      } else if (this.checkedResult.length > 1) {
        Toast("回执单不能批量进行操作哦");
      } else {
        let param = new FormData();
        if (files.file.size > 1048576) {
          //根据图片字节去判断是否压缩图片 1M
          lrz(files.file, { quality: 0.5 }).then(async (rst) => {
            if (rst.size > 1048576) {
              Toast.fail("您上传的文件太大");
              return false;
            }
            let base64 = this.dataURLtoFile(rst.base64, files.file.name);
            param.append("file", base64);
            let res = await this.$Api.uploadPicture(
              this.checkedResult[0].phone,
              param
            );
            if (res.data.code == 200) {
              Toast.success("上传回执单成功");
            } else {
              Toast.fail("上传失败，请重新上传");
            }
          });
        } else {
          param.append("file", files.file);
          let res = await this.$Api.uploadPicture(
            this.checkedResult[0].phone,
            param
          );
          if (res.data.code == 200) {
            Toast.success("上传回执单成功");
          } else {
            Toast.fail("上传失败，请重新上传");
          }
        }
      }
    },

    //打点
    dotClick() {
      if (this.checkedResult.length <= 0) {
        Toast.fail("请先选择订单");
      } else if (this.checkedResult.length > 1) {
        Toast("不能批量打点哦");
      } else {
        let arr = this.checkedResult.map((item) => {
          return {
            waybillId: item.waybillId,
            orderId: item.orderId,
          };
        });
        this.$router.push({
          path: "/main",
          query: {
            orderId: arr[0].orderId,
            waybillId: arr[0].waybillId,
          },
        });
      }
    },

    //结束订单
    async CloseOrder() {
      if (this.checkedResult.length <= 0) {
        Toast("请先选择订单");
      } else {
        let arr = [];
        this.checkedResult.map((item) => {
          arr.push(item.waybillNo);
        });
        let strArr = arr.join(",");
        let params = {
          waybillNo: strArr,
        };
        let res = await this.$Api.endWaybillByWaybillNo(params);
        if (res.data == true) {
          this.showPopwaybill = false;
          Toast.success("结束订单成功");
          this.query.page = 0;
          this.dataList = [];
          this.noCarDataList = [];
          this.getList();
        } else {
          Toast("结束订单失败");
        }
      }
    },
    // 关闭弹出层后，清空表单
    closeClick() {
      this.form = {
        carNo: '',
        driverName: '',
        driverPhone: '',
        vehicleLength: '',
        cargoSize: '',
        cargoNum: '',
      }
    },
    // 补全车辆信息提交
    onSubmit(values) {
      if(values) {
        console.log('submit', values);
      }
    },
  },
  computed: {
    //点击显示隐藏更多
    // showList:function() {
    //   if (this.showAll == false) {
    //     var showList = []
    //     if (this.orderList.length > 3) {
    //       for (let i = 0; i < 3; i++) {
    //         showList.push(this.orderList[i])
    //       }
    //     }else {
    //       showList = this.orderList
    //     }
    //     return showList
    //   } else {
    //     return this.orderList
    //   }
    // }
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 84vh;
  overflow: scroll;
  font-size: 0.37rem;
  // height: 18rem;
  .deriver_main {
    width: 9.14rem;
    margin: 0 0 0.23rem 0.43rem;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.64rem;
    position: relative;
  }
  .driverInfo {
    .driverInfo_detail {
      position: relative;
      .driver_name {
        height: 0.64rem;
        font-size: 0.43rem;
        background-image: url("../../assets/img/icon/waybill_user.png");
        background-repeat: no-repeat;
        padding-left: 0.64rem;
        line-height: 0.69rem;
        span {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.32rem;
          width: 1.15rem;
          height: 0.45rem;
          line-height: 0.45rem;
          text-align: center;
          border-radius: 0.1rem;
        }
        .useYd {
          padding: 0;
          color: #fb6600;
          background-color: rgba($color: #fb6600, $alpha: 0.2);
        }
        .noYd {
          padding: 0;
          color: #999999;
          background-color: rgba($color: #999999, $alpha: 0.2);
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        padding: 0.16rem 0;
      }
      span:nth-child(2) {
        text-align: right;
      }
    }
  }
  .order {
    padding: 0.13rem 0 0.3rem 0.38rem;
    border-bottom: 5px solid #f5f5f5;
  }
  .orderInfo {
    position: relative;
    overflow: visible;
    .mark_ddd {
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.64rem;
      height: 0.64rem;
      background-repeat: no-repeat;
      background-size: 0.64rem;
      background-image: url("../../assets/img/icon/waybill_ddd.png");
    }
    .mark_zk {
      position: absolute;
      width: 0.42rem;
      height: 0.42rem;
      bottom: -0.5rem;
      left: 50%;
      margin-left: -0.18rem;
      background-repeat: no-repeat;
      background-size: 0.42rem;
      background-image: url("../../assets/img/icon/zk_zd.png");
    }
  }
  .orderList {
    h3 {
      font-size: 0.32rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      flex: 1;
      font-size: 0.32rem;
      // height: 0.45rem;
      height: 0.45rem;
      line-height: 0.45rem;
      &:nth-child(1) {
        flex: 1;
        padding: 0.16rem 0 0 0.64rem;
        background-image: url("../../assets/img/icon/waybill_order2x.png");
        background-repeat: no-repeat;
        background-size: 0.64rem;
      }
      &:nth-child(2) {
        width: 3.1rem;
        padding: 0 0 0.15rem 0.7rem;
      }
    }
    .orderList_time {
      .van-image {
        width: 0.64rem;
        height: 0.64rem;
        vertical-align: middle;
        padding-bottom: 0.1rem;
      }
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.list-status {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 10;
  opacity: 0.6;

  .list-outer-ring {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid #fb6600;
    border-radius: 50%;
    z-index: 10;
  }
  .list-inner-circle {
    position: absolute;
    top: 5px;
    right: -7px;
    width: 50px;
    height: 50px;
    border: 1px solid #fb6600;
    border-radius: 50%;
    z-index: 10;
  }
  span {
    color: #fff;
    position: absolute;
    top: 22px;
    right: -13px;
    width: 70px;
    text-align: center;
    transform: rotate(-45deg);
    background: #f60;
  }
}
.fenge {
  height: 0.03rem;
  margin-top: 0.27rem;
  background: #f5f5f5;
}

.order {
  font-size: 0.37rem;
  position: relative;
  .order_container {
    // background-color: pink;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_jh {
    // background-color: pink;
    // height: 4.03rem;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 5px 0.27rem;
      color: #999999;
    }
    span:nth-child(2) {
      padding-right: 0.61rem;
    }
  }
  .order_userinfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .order_userinfo_check {
      width: 1.04rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .checked {
        width: 0.5rem;
        height: 0.5rem;
        /deep/.van-checkbox__icon .van-icon {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
    .order_userinfo_rigth {
      flex: 1;
      height: 100%;
      padding-right: 0.3rem;
      .order_userinfo_rigth1 {
        padding-top: 0.21rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.53rem;
        span {
          color: #999999;
        }
        .order_userinfo_rigth_item {
          display: flex;
          justify-content: left;
          align-items: center;
          span {
            color: #000000;
            height: 0.64rem;
            line-height: 0.64rem;
            padding-left: 0.6rem;
            background: url("../../assets/img/icon/order_user2x.png") no-repeat;
            background-size: 0.64rem;
          }
          .tel_icon {
            background: url("../../assets/img/icon/order_tel2x.png") no-repeat;
            background-size: 0.64rem;
          }
        }
      }
    }
  }
}
.order_userinfo_rigth_adress {
  // height: 0.64rem;

  background: url("../../assets/img/icon/order_adress2x.png") no-repeat;
  background-size: 0.64rem;
  // padding-top: 0.2rem;
  position: relative;
  // margin-bottom: -0.3rem;
  .adress_text {
    padding-left: 0.6rem;
    line-height: 0.6rem;
    // margin-top: -0.2rem;
    // line-height: 0.3rem;
  }
}
.order_tile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999999;
  .order_title_img1 {
    width: 0.64rem;
    height: 0.64rem;
  }
  .order_title_img2 {
    width: 1.55rem;
    height: 0.48rem;
    padding-left: 2.12rem;
  }
  .order_no {
    color: #999999;
    width: 5.44rem;
    height: 0.53rem;
    font-size: 0.37rem;
    line-height: 0.53rem;
    // margin-left: 0.64rem;
  }
  .order_time {
    padding-right: 0.28rem;
  }
}
.qrcodeIcon {
  position: absolute;
  bottom: 0.3rem;
  left: 0.45rem;
  width: 20px;
  height: 20px;
  background: url("../../assets/img/icon/qrcodeIcon.png");
  background-size: 100%;
}
.qrcodebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding-top: 2rem;
  box-sizing: border-box;
  /deep/ .van-icon-cross {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
  }
  .qrcodeImg {
    margin: 1rem 0 0.6rem 0;
  }
  .order_userinfo_rigth {
    flex: 1;
    height: 100%;
    padding: 0.3rem;
    .order_userinfo_rigth1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #999999;
      }
      .order_userinfo_rigth_item {
        display: flex;
        justify-content: left;
        align-items: center;
        span {
          color: #000000;
          height: 0.64rem;
          line-height: 0.64rem;
          padding-left: 0.6rem;
          background: url("../../assets/img/icon/order_user2x.png") no-repeat;
          background-size: 0.64rem;
        }
        .tel_icon {
          background: url("../../assets/img/icon/order_tel2x.png") no-repeat;
          background-size: 0.64rem;
        }
      }
    }
    .order_userinfo_rigth_adress {
      background: url("../../assets/img/icon/order_adress2x.png") no-repeat;
      background-size: 0.64rem;
      position: relative;
      margin: 0.2rem 0;
      .adress_text {
        padding-left: 0.6rem;
        line-height: 0.6rem;
        margin-top: -0.2rem;
      }
    }
  }
}

.detail_title {
  padding: 0.4rem 0 0.2rem 0;
  height: 0.59rem;
  line-height: 0.59rem;
  text-align: center;
  font-size: 0.43rem;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0.1rem 0.1rem 0.1rem #cccccc;
  background: #ffffff;
  height: 1.33rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  .btn_qx,
  .btn_sub {
    // width: 3.73rem;
    // height: 0.85rem;
    padding: 0 0.45rem;
    color: #ffffff;
    line-height: 0.85rem;
    text-align: center;

    border-radius: 0.85rem;
  }
  .btn_qx {
    background: #0094ff;
  }
  .btn_sub {
    background: #fb6600;
  }
}

// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
}
h3 {
  font-size: 0.37rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #f5f5f5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #fb6600;
    background: rgba($color: #fb6600, $alpha: 0.1);
  }
}
.screen_orderNo,
.screen_ys_orderNo,
.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #fb6600;
  margin: 0 auto;
}

.searchValue {
  background: #f5f5f5;
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  padding-bottom: 0.2rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #fb6600;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
      background: #f5f5f5;
      border: 0;
      line-height: 0.8rem;
      margin-top: -0.2rem;
    }
    /deep/.el-input__icon {
      opacity: 0;
    }
  }
}

/deep/.van-popup {
  font-size: 14px;
  min-height: 55%;
  max-height: 85%;
  z-index: 1000 !important;
}
/deep/.el-picker-panel .el-date-picker .el-popper {
  z-index: 2999 !important;
}
/deep/.van-overlay {
  z-index: 889 !important;
}
.orderBox {
  padding-bottom: 2.33rem;
  max-height: 10rem;
  overflow: scroll;
}
.noLoading {
  text-align: center;
  height: 0.5rem;
  font-size: 0.37rem;
  color: #999999;
}
</style>
